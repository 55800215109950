<template>

  <div class="enableContainer">
    <a-spin :spinning="spinning">
    <div class="enableBox">
      <div class="menu">
        <a-menu
            mode="inline"
            v-model="menu"
        >
          <a-menu-item
              v-for="(item,index) in typeInfo"
              :key="item.code"
              @click="menuChange(item.moduleFuncs,index)"
          >
            {{ item.title }}
          </a-menu-item>
        </a-menu>
      </div>
      <div class="main">
        <a-button type="primary" @click="addOpen">新增</a-button>
        <a-table
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            style="width: 99%;margin-top: 15px"
            :rowKey='record=>record.id'
        >
          <!--          图标-->
          <span slot="icon" slot-scope="text,item">
            <img :src="item.displayUrl" alt="" v-if="item.displayUrl" style="height: 50px;width: 50px">
          </span>
          <!--          状态-->
          <span slot="status" slot-scope="text,item">
            <a-switch
                checked-children="已发布"
                un-checked-children="未发布"
                v-model="item.status === 'OPEN' ? true : false" @click="tableStatusOpenDown(item)"/>
          </span>
          <!--          排序-->
          <template slot="sort" slot-scope="text,record,index">
            <span v-if="index === tableData.length - 1">
              <a style="margin-left: 10px" v-if="index != 0" @click="postRemoveUpDown(record, 'up')">上移</a>
            </span>
            <span v-if="index !== 0 && index !== tableData.length - 1">
              <a style="margin-left: 10px" @click="postRemoveUpDown(record, 'up')">上移</a>
              <a style="margin-left: 10px" @click="postRemoveUpDown(record, 'down')">下移</a>
            </span>
            <span v-if="index === 0">
              <a style="margin-left: 10px" @click="postRemoveUpDown(record, 'down')">下移</a>
            </span>
          </template>
          <!--          操作-->
          <span slot="operation" slot-scope="text,record,index">
              <a style="margin-left: 10px" @click="editOpen(record)">修改</a>
              <a-popconfirm
                  title="确定要删除吗？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="tableRemove(record)"
              >
              <a style="margin-left: 10px">删除</a>
              </a-popconfirm>
            </span>
        </a-table>
      </div>
<!--------------------------------------对话框------------------------------------>
<!--      新增-->
      <a-drawer
          title="新增"
          :width="480"
          :visible="addVisible"
          @close="addClose"
      >
          <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="addForm">
            <a-form-model-item  label="功能图标" prop="displayUrl">
              <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
                <div class="uploadImg">
                  <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addIcon')" ref="addIcon"/>
                </div>
                <div class="upload_image">
                  <img
                      alt=""
                      v-if="addForm.displayUrl"
                      style="width: 95px;height:95px;"
                      :src="addForm.displayUrl"
                  />
                </div>
              </div>
              <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
            </a-form-model-item>
            <a-form-model-item label="功能名称" prop="title">
              <a-input v-model="addForm.title" placeholder="请输入" :maxLength="200"/>
            </a-form-model-item>
            <a-form-model-item label="功能类型" prop="moduleCode">
              <a-select placeholder="请选择"  v-model="addForm.moduleCode">
                <a-select-option v-for="list in selectInfo" :key="list.id" :value="list.value">
                  {{list.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="开放范围" prop="range">
              <a-checkbox-group v-model="checkedList" :options="plainOptions"/>
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-switch checked-children="发布" v-model="addForm.status === 'OPEN' ? true : false" @change="addStatus"/>
            </a-form-model-item>
          </a-form-model>
          <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          }"
          >
            <a-button @click="addClose">
              取消
            </a-button>
            <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
              确定
            </a-button>

          </div>
      </a-drawer>

<!--      修改-->
      <a-drawer
          title="修改"
          :width="480"
          :visible="editVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="editClose"
      >
        <a-form-model :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="editForm">
          <a-form-model-item label="功能图标" prop="displayUrl">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" ref="editIcon" @change="uploadPic('editIcon')"/>
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.displayUrl"
                    style="width: 95px;height:95px;"
                    :src="editForm.displayUrl"
                />
              </div>
            </div>
            <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item label="功能名称" prop="title">
            <a-input v-model="editForm.title" placeholder="请输入" :maxLength="200"/>
          </a-form-model-item>
          <a-form-model-item label="功能类型" prop="moduleCode">
            <a-select placeholder="请选择" v-model="editForm.moduleCode">
              <a-select-option v-for="list in selectInfo" :key="list.id" :value="list.value">
                {{list.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="开放范围" prop="range">
            <a-checkbox-group v-model="checkedList" :options="plainOptions"/>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-switch checked-children="发布" v-model="editForm.status === 'OPEN' ? true : false" @change="editStatus"/>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button @click="editClose">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="editSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
    </div>
    </a-spin>
  </div>

</template>

<script>
import {
  deleteEnableRemove,
  getEnableFunction,
  postEnableAdd,
  postEnableUpDown,
  putEnableUpdate
} from "@/service/systemApi";
import {update} from "@/utils/update";

export default {
  name: 'EnableFunction',
  data() {
    const renderContent = (value) => { // 开放范围中的数据渲染前进行格式化
      if (value === undefined) { // 判断空
        return ''
      }
      let length = value.split('/')
      let inputValue = length.map((map,index) => {
        this.plainOptions.some(some => {
          if (some.value === map) {
            map = some.label
            return
          }
        })
        if (length.length - 1 === index) {
          return map
        }
        return map + '/'
      })
      return inputValue // 将处理后的值返回
    }
    let validateRange = (rule, value, callback) => { // 开放范围校验规则
      if (this.checkedList.length < 1) {
        callback(new Error('请至少选择一项开放范围'));
      }else {
        callback();
      }
    };
    return {
      menu: [],
      typeInfo: [],
      spinning: false,
      checkedList: [],
      plainOptions: [
        {
          label: '小程序',
          value: 'MINA'
        },
        {
          label: 'APP-安卓',
          value: 'APP_ANDROID'
        },
        {
          label: 'APP-苹果',
          value: 'APP_IOS'
        }
      ],
      tableColumns: [
        {
          title: '图标',
          dataIndex: 'displayUrl',
          width: '10%',
          scopedSlots: {customRender: 'icon'}
        },
        {
          title: '功能名称',
          width: '20%',
          dataIndex: 'title'
        },
        {
          title: '开放范围',
          width: '20%',
          dataIndex: 'openRange',
          customRender: renderContent
        },
        {
          title: '状态',
          width: '15%',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '排序',
          width: '15%',
          align: 'left',
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '操作',
          width: '15%',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      tableData: [],
      addVisible: false,
      editVisible: false,
      addForm: {
        displayUrl: '',
        status: 'OPEN'
      },
      editForm: {},
      menuCode: 0,
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      selectInfo: [
        {
          id: 1,
          name: 'Banner广告',
          value: 'AD'
        },
        {
          id: 2,
          name: '直播',
          value: 'LIVE_METTING'
        },
        {
          id: 3,
          name: '点播',
          value: 'VOD'
        },
        {
          id: 4,
          name: '咨询',
          value: 'CONSULT'
        },
        {
          id: 5,
          name: '会议',
          value: 'HZ_METTING'
        },
        {
          id: 6,
          name: '文章',
          value: 'ARTICLE'
        }
      ],
      addFormRules: {
        displayUrl: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        moduleCode: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        range: [{ validator:validateRange,required: true, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"App首页功能管理")
    this.getEnableFunction()
  },
  methods: {
    menuChange(data,index) { // 切换菜单时改变数据
      this.tableData = data
      this.menuCode = index
    },
    addOpen () {
      this.checkedList = []
      this.addVisible = true
    },
    editStatus () { // 点击按钮切换状态
      this.editForm.status === 'OPEN' ? this.editForm.status = 'BAN' : this.editForm.status = 'OPEN'
    },
    addStatus () { // 点击按钮切换状态
      this.addForm.status === 'OPEN' ? this.addForm.status = 'BAN' : this.addForm.status = 'OPEN'
    },
    async editOpen (row) { // 修改打开
      this.spinning = true
      let data = {
        "id": row.id,
        "type": "sing"
      }
      const response = await getEnableFunction(data)
      if (response.code === 0) {
        this.editForm = response.data[0]
        let range = this.checkedList = response.data[0].openRange
        if (range) { // 将收到的值切割
          this.checkedList = response.data[0].openRange.split('/')
        }
        this.spinning = false
      }
      this.editVisible = true
    },
    addClose () {
      this.addVisible = false
    },
    editClose () {
      this.editVisible = false
    },
    async getEnableFunction () { // 获取 Table 列表
      this.spinning = true
      const response = await getEnableFunction()
      if (response.code === 0) {
        this.typeInfo = response.data
        this.menu = [response.data[this.menuCode].code]
        this.tableData = response.data[this.menuCode].moduleFuncs
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async postRemoveUpDown (row,type) { // 上移下移
      this.spinning = true
      let data = {
        "code": row.columnCode,
        "moveUpDown": type
      }
      const response = await postEnableUpDown(row.id,data)
      if (response.code === 0) {
        await this.getEnableFunction()
        this.$message.success("设置成功")
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async editSubmit () { // 修改提交
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let editData = this.editForm
          let Id = editData.id
          let list = ''
          this.checkedList.map( (v,index) => {
            if (this.checkedList.length - 1 === index) {
              return list += v
            }
            return list += v + '/'
          })
          let data = {
            "displayUrl": editData.displayUrl,
            "title": editData.title,
            "status": editData.status,
            "openRange": list,
            "moduleCode": editData.moduleCode,
            "columnCode": editData.columnCode
          }
          const response = await putEnableUpdate(Id,data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            await this.getEnableFunction()
            this.editVisible = false
            this.$refs.editIcon.value = null
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写全部表单")
        }
      })

    },
    async uploadPic(type){ // 图片上传
      let file = this.$refs[type].files[0];
      if ( file !== undefined ) {
        if (file.size < 512000){ // 限制大小
          const image = await update(file)
          if(image.code === 0){
            if ( type === "addIcon" ) {
              this.addForm.displayUrl = image.data.url
            }else {
              this.editForm.displayUrl = image.data.url
            }
            this.$message.success("图片上传成功！")
          }else {
            this.$message.error(image.message)
          }
        }else {
          this.$message.error("请上传小于500kb的图片")
        }
      }
    },
    async addSubmit () { // 新增提交
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let addData = this.addForm
          let list = ''
          this.checkedList.map( (v,index) => {
            if (this.checkedList.length - 1 === index) {
              return list += v
            }
            return list += v + '/'
          })
          let data = {
            "displayUrl": addData.displayUrl,
            "title": addData.title,
            "status": addData.status,
            "columnCode": this.menu[0],
            "openRange": list,
            "moduleCode": addData.moduleCode
          }
          const response = await postEnableAdd(data)
          if (response.code === 0) {
            await this.getEnableFunction()
            this.$message.success("新增成功")
            this.addVisible = false
            this.addForm = {
              displayUrl: '',
              status: 'OPEN'
            }
          }else {
            this.$message.warning(response.message)
          }
        }else {
          this.$message.error("请填写全部表单")
        }
        this.spinning = false
      })
    },
    async tableRemove (row) { // 删除
      this.spinning = true
      const response = await deleteEnableRemove(row.id)
      if (response.code === 0) {
        this.$message.success("删除成功")
        await this.getEnableFunction()
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async tableStatusOpenDown (row) { // Table 列表中直接修改状态
      this.spinning = true
      let data = {
        "status": row.status === 'OPEN' ? 'BAN' : 'OPEN'
      }
      const response = await putEnableUpdate(row.id, data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        await this.getEnableFunction()
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
  }
}
</script>

<style lang="scss" scoped>
.enableContainer {
  margin: -15px;

  .enableBox {
    display: flex;
    .menu {
      width: 12%;
      border-right: 10px solid rgba(240, 242, 245, 1);
      min-height: 660px;
    }
    .main {
      padding: 15px;
      width: 100%;
    }
  }

}

.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
}
</style>